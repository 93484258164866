import React from "react"
import { Link } from "gatsby"
import { useSelector } from "react-redux"
import sanitizeTitle from "../../utilities"

const OpenRoles = () => {
  const sectionData = useSelector(state => state.careers)
  const language = useSelector(state => state.global?.language)

  const {
    openingRoles: { title, list, paragraph, text },
  } = sectionData

  return (
    <section className="career_boxset">
      <div className="container p-0">
        <div className="row text_side m-0">
          <div className="txt_block">
            <div className="lftbrdr">
              <h6>{title[language]}</h6>
              <h1>{text[language]}</h1>
            </div>
          </div>
          <div className="para_block">
            <p>{paragraph[language]}</p>
          </div>
        </div>
        <div className="career_cardbox">
          {list.map((role, index) => (
            <div className="career_card" key={index}>
              <h6>{role.department[language]}</h6>
              <h2>{role.positionTitle[language]}</h2>
              <span>{role.location[language]}</span>
              <Link
                to={`/careers/${sanitizeTitle(role.positionTitle.en)}`}
                data-toggle="modal"
                data-target="#basicModal1"
              >
                {role.anchorText.en}
                <i className="arrow" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default OpenRoles
