import React from "react"
import { useSelector } from "react-redux"
import Lottie from "react-lottie"
import colaborative from "../../images/json_file/Colaborative.json"
import friendly from "../../images/json_file/friendly.json"
import flexible from "../../images/json_file/flexible.json"
import personalGrowth from "../../images/json_file/personal growth.json"

const PartnerWithWD = () => {
  const sectionData = useSelector(state => state.careers)
  const language = useSelector(state => state.global?.language)

  const {
    careerBlock: { title, text, paragraph, blockRepeater },
  } = sectionData

  const icons = [colaborative, friendly, flexible, personalGrowth]
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <section className="career_block">
      <div className="container">
        <div className="row text_side">
          <div className="txt_block">
            <div className="lftbrdr">
              <h6>{title[language]}</h6>
              <h2>{text[language]}</h2>
              <p>{paragraph[language]}</p>
            </div>
          </div>
        </div>
        <div className="career_txtblk">
          {blockRepeater.map((cic, index) => (
            <div className="quality" key={index}>
              <div className="icon_player">
                <Lottie
                  options={{ ...defaultOptions, animationData: icons[index] }}
                />
              </div>
              <h3>{cic.title[language]}</h3>
              <p>{cic.text[language]}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default PartnerWithWD
