import React from "react"
import { useSelector } from "react-redux"

const MainSection = () => {
  const sectionData = useSelector(state => state.careers)
  const language = useSelector(state => state.global?.language)

  const {
    banner: { title, text, hightlightedText, image, mobileImage },
  } = sectionData

  return (
    <div className="banner about_banner career_banner">
      <div className="container">
        <div className="banner_txt">
          <div>
            <div className="light img-fluid d-sm-block d-md-none img_center">
              <img src={mobileImage.sourceUrl} alt={mobileImage.title} />
            </div>
            <h1 className="split-text">{title[language]}</h1>
            <p className="split-text">
              <span className="banner_txtclr">
                {hightlightedText[language]}
              </span>
              {text[language]}
            </p>
          </div>
        </div>
      </div>
      <div className="light img-fluid d-none d-sm-none d-md-none d-lg-block">
        <img src={image.sourceUrl} alt={image.title} />
      </div>
    </div>
  )
}

export default MainSection
