import React from "react"
import SectionCommon from "./Mainsection"
import PartnerwithWD from "./PartnerWithWD"
import OpenRoles from "./OpenRoles"
import Explore from "../Common/Explore"

const NewHomepage = () => (
  <>
    <SectionCommon />
    <PartnerwithWD />
    <OpenRoles />
    <Explore />
  </>
)

export default NewHomepage
