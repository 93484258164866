import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CareerPageContent from "../components/Careers"
import { StaticQuery, graphql } from "gatsby"
import {
  CAREERS_PAGE_QUERY_REQUEST,
  CAREERS_PAGE_QUERY_SUCCESS,
  CAREERS_PAGE_QUERY_FAILED,
} from "../store/actionTypes"
import { useDispatch } from "react-redux"

const Careers = () => {
  const dispatch = useDispatch()
  const query = graphql`
    {
      wdwordpress {
        page(id: "cGFnZToxMjQ=") {
          careers_contents {
            banner {
              hightlightedText {
                en
                sv
              }
              image {
                title
                sourceUrl
              }
              mobileImage {
                title
                sourceUrl
              }
              preTitle {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            careerBlock {
              blockRepeater {
                text {
                  en
                  sv
                }
                title {
                  en
                  sv
                }
              }
              paragraph {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            connectedBlock {
              blocks {
                buttontext {
                  en
                  sv
                }
                link
                text {
                  en
                  sv
                }
                title {
                  en
                  sv
                }
              }
              title {
                en
                sv
              }
            }
            form {
              attachFile {
                formats {
                  en
                  sv
                }
                title {
                  en
                  sv
                }
              }
              buttonText {
                en
                sv
              }
              fields {
                errorMessage {
                  en
                  sv
                }
                label {
                  en
                  sv
                }
                name
                type
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            openingRoles {
              list {
                anchorText {
                  en
                  sv
                }
                department {
                  en
                  sv
                }
                description {
                  en
                  sv
                }
                location {
                  en
                  sv
                }
                positionTitle {
                  en
                  sv
                }
                responsibilities {
                  list {
                    label {
                      en
                      sv
                    }
                  }
                  title {
                    en
                    sv
                  }
                }
                skills {
                  list {
                    label {
                      en
                      sv
                    }
                  }
                  title {
                    en
                    sv
                  }
                }
              }
              paragraph {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
          }
        }
      }
    }
  `
  return (
    <StaticQuery
      query={query}
      render={({ wdwordpress }) => {
        dispatch({ type: CAREERS_PAGE_QUERY_REQUEST })
        dispatch({ type: CAREERS_PAGE_QUERY_SUCCESS, payload: wdwordpress })
        return (
          <Layout>
            <SEO title="Careers" pageId="cGFnZToxMjQ=" />
            <CareerPageContent />
          </Layout>
        )
      }}
    />
  )
}

export default Careers
